import React from 'react';

const Logo = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="32.000000pt" height="32.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#A07470" stroke="none">
        <path d="M2235 5104 c-226 -30 -419 -80 -610 -157 -355 -144 -599 -302 -865
        -564 -271 -267 -447 -535 -594 -905 -115 -292 -166 -573 -166 -918 0 -345 51
        -626 166 -918 143 -360 306 -612 571 -882 267 -271 535 -447 905 -594 292
        -115 573 -166 918 -166 345 0 626 51 918 166 360 143 612 306 882 571 271 267
        447 535 594 905 115 292 166 573 166 918 0 345 -51 626 -166 918 -143 360
        -306 612 -571 882 -267 271 -535 447 -905 594 -191 75 -373 121 -601 151 -146
        19 -500 19 -642 -1z m530 -1054 c175 -20 344 -76 497 -166 90 -53 226 -157
        234 -179 6 -14 -173 -243 -190 -244 -5 -1 -27 14 -50 33 -122 99 -292 182
        -436 212 -116 25 -331 25 -420 1 -108 -29 -202 -81 -258 -142 -68 -74 -94
        -144 -96 -255 -1 -74 3 -92 27 -142 33 -67 113 -150 191 -197 30 -18 220 -104
        420 -191 441 -192 537 -240 641 -324 178 -144 260 -303 272 -526 24 -449 -317
        -800 -838 -861 -127 -14 -362 -7 -479 15 -256 50 -501 173 -709 357 l-53 46
        103 119 c56 66 107 125 114 132 10 11 24 3 76 -43 153 -137 337 -233 541 -282
        103 -25 386 -25 478 0 270 72 413 261 380 499 -22 156 -113 260 -315 358 -66
        32 -257 117 -425 190 -425 184 -500 228 -628 365 -132 141 -188 297 -179 495
        22 479 502 797 1102 730z"/>
    </g>
    </svg>
  )
};

export default Logo;
